@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

/* ===== Body of the page ==== */

body {
  height: 4400px;
  background: black;
  color: white;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

a {
  color: white
}

a:visited {
  color: white
}

.purple-overlay {
  background-color: purple;
  opacity: 0.2;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

/* Add some content at the bottom of the video/page */
.content {
  position: absolute;
  /* top: 0; */
  /* background: rgba(0, 0, 0, 0.5); */
  color: #f1f1f1;
  width: 100vw;
  height: 4400px;
  z-index: 3;
}


/* ===== Title ==== */

.title {
  position: absolute;
  width: 100%;
  margin-top: 300px;
  font-family: "Cormorant Garamond", serif;
  font-size: 4rem;
  font-style: italic;
}

.sub-title {
  position: relative;
  top: 0px;
  left: 20%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 3rem;
}

.main-title {
  position: relative;
  top: 300px;
  left: 30%;
  font-size: 15rem;
}

/* ===== SVG ==== */

svg {
  /* display: inline-block; */
  width: 100%;
  z-index: 4;
}

.line {
  fill: none;
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 2px;
}

.theLine {
  z-index: 5;
}

.waka {
  transform: scale(0.2);
  z-index: 5;
}

.waka path:nth-child(-n + 2) {
  fill: #646fd4 !important;
}

text {
  fill: rgba(0, 0, 0, 0.5);
  font-size: 25px;
  font-style: italic;
  font-family: "Cormorant Garamond", serif;
  /* visibility: hidden; */
}

.yearTitle {
  font-size: 0.6rem;
  fill: rgba(255, 255, 255, 0.6);
  /* fill: rgba(0, 0, 0, 0.5); */
  font-style: italic;
  font-family: "Cormorant Garamond", serif;
}

/* .rightSide {
  font-size: 1.2rem;
  text-align: right;
} */

.storyLine {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1px;
  visibility: hidden;
}

.storyText {
  fill: rgba(255, 255, 255, 0.9);
  font-size: 0.4rem;
  font-style: italic;
  font-family: "Cormorant Garamond", serif;
  visibility: hidden;
}

.ball {
  visibility: hidden;
}




/* =====================
      STORY SHOW/HIDES
   ===================== */
.storyUiTeRangiHidden,
.storyMuturangawhenuaHidden,
.storyArutanganukuHidden,
.storyTangiiaHidden {
  /* border: 1px solid red  */
  opacity: 0;
  transition: all 0.3s;
  z-index: 3;
}

.storyUiTeRangiShow,
.storyMuturangawhenuaShow,
.storyArutanganukuShow,
.storyTangiiaShow {
  opacity: 1;
  transition: all 0.3s;
}

.story-title {
  /* fill: rgba(255,255,255,0.9);; */
  font-size: 3rem;
  font-style: italic;
  font-family: "Cormorant Garamond", serif;
}

p {
  margin: 0;
}





/* ===== GSAP ==== */

.gs-dev-tools {
  z-index: 100;
}

/* ===== Circle/pulse animation ==== */

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  z-index: 4;
}

.pulse {
  animation: pulse-animation 2s infinite;
  z-index: 4;
}


@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}



/* ===== Lightboxes ==== */

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 5;
  display: none;

}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noscroll {
  overflow: hidden;
}

.video-player {
  width: 50%;
  position: relative;
}

#info-lightbox {
  padding: 50px 50px 50px 50px;
  overflow-y: scroll;
  height: 120vh;
  align-items: flex-start;
}

#info-lightbox p {
  padding-right: 100px;
  margin-bottom: 20px;
  /* font-style: italic; */
  font-family: "Cormorant Garamond", serif;
}

.info-title {
  font-family: "Cormorant Garamond", serif;
  font-style: italic;
}

iframe {
  width: 80%;
  object-fit: cover;
}

.close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 5;
  cursor: pointer;
}

.info-button {
  position: absolute;
  top: 400px;
  left: 200px;
  z-index: 6;
  cursor: pointer;
  opacity: 0.3;
}